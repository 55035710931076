<template>
  <div style="width: 450px;height:600px;  margin-left: 20rem">
  </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
    sliderModel: {
      value: [0, 100]
    },        
      }
    }
  }
</script>

